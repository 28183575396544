import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import Container from "../components/Container"
import ThemeContext from "../context/ThemeContext"
import Seo from "../components/Seo"

const edjsHTML = require("editorjs-html")

const ArticlePage = ({ data }) => {
  const article = data.strapiArticle
  const articleBlocks = JSON.parse(article.PostContent.data.PostContent)
  const edjsParser = edjsHTML()
  const html = edjsParser.parse(articleBlocks)

  return (
    <ThemeContext.Consumer>
      {theme => (
        <Layout article={true}>
          <Seo title={article.Title} description={article.Description} />
          <Container>
            <div className="max-w-[45rem] mx-auto mb-16 md:mb-32 lg:mt-16">
              <header className="max-w-4xl pt-8 pb-4">
                <h1 className="text-[2.625rem] md:text-[3.375rem] lg:text-6xl mb-4 font-light leading-none">
                  {article.Title}
                </h1>
                <div className="flex justify-between items-center border-b border-current pb-2 uppercase text-sm tracking-widest">
                  <div>{article.PublishDate}</div>
                  <div className="flex gap-3">
                    {article.categories &&
                      article.categories.map((cat, idx) => (
                        <div
                          key={idx}
                          className="text-xs border border-current rounded-full inline-block px-1.5"
                        >
                          {cat.Name}
                        </div>
                      ))}
                  </div>
                </div>
              </header>
              <main
                className={`prose prose-invert  lg:prose-lg max-w-none prose-a:font-light prose-a:text-current prose-li:marker:text-current prose-h2:font-light prose-h2:text-current ${
                  theme.playful ? "text-deeptech-purple" : "text-black"
                }`}
              >
                {html.map((block, index) => (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: block.replace(/href/g, "target='_blank' href"),
                    }}
                  />
                ))}
              </main>
            </div>
          </Container>
        </Layout>
      )}
    </ThemeContext.Consumer>
  )
}

export const pageQuery = graphql`
  query ($slug: String) {
    strapiArticle(Slug: { eq: $slug }) {
      id
      Slug
      Title
      ExternalPostUrl
      Description
      PublishDate(formatString: "D MMM YYYY")
      PostContent {
        data {
          PostContent
        }
      }
      categories {
        Name
      }
    }
  }
`

export default ArticlePage
